import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitsService } from '../../services/units.service';
import {
  Complex,
  LocationAmenity,
  locationCategories,
  UnitImage,
} from '../../store/models/complex.model';
import { mobileSM, maxImageWidth } from '../../store/models/unit.model';
import { MatDialog } from '@angular/material/dialog';
import { UnitEnquiryDialogComponent } from '../unit-booking/unit-enquiry-dialog/unit-enquiry-dialog.component';
import { ApiService } from 'src/app/api.service';
import { Subscription, Observable, of } from 'rxjs';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';
import { SearchParamsService } from 'src/app/services/search-params.service';
import { mainsite, mm, staging, localhost } from '../../store/models/hostings';
import _ from 'lodash';
import { Title, Meta } from '@angular/platform-browser';
import { EventService } from 'src/app/services/event.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location, ViewportScroller } from '@angular/common';

@Component({
  selector: 'twbooking-complex-details',
  templateUrl: './complex-details.component.html',
  styleUrls: ['./complex-details.component.scss'],
})
export class ComplexDetailsComponent implements OnInit, OnDestroy {
  private fragment: string | null = null;

  private sub: any;
  unitCode: string;
  unitSlug: string;
  complex: Complex = null;
  unitExist = true;
  isMobile = true;
  isSmall = true;
  loading = true;
  shortText = true;
  showMoreImages = false;
  displayedColumns: string[] = ['Thumbnail', 'Description', 'Actions'];
  subMedia: Subscription;
  reviewsRating = -1;
  allDining = false;
  showAllUnits = false;
  allSPA = false;
  spaImagePrefix = '';
  datesfromSearch = false;
  showAllAmenities = false;
  moreThen200words = true;

  /* availability widget */
  fastRatesWidget: UntypedFormGroup;
  /* dates */
  arrivalField: moment.Moment;
  departureField: moment.Moment;
  myStay = 0; // length of requested stay
  lastChangedArrival = false;
  /* Guests */

  numPets = 0;
  petOptions: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  guests: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  guests_children: number[] = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
  ];

  nb = true;
  mmOnly = false;

  pageSize = 20;
  offset = 0;
  unitsCount = 0;
  unitDisplay: Observable<any>;
  noResult = false;
  noMoreUnits = false;

  loadingUnits = true;
  searchQuery: any = {};

  readonly locationCategories: any[] = locationCategories;

  locationAmenitiesParsed: {
    Category: string;
    icon: string;
    List: string[];
  }[] = null;

  constructor(
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private unitService: UnitsService,
    public dialog: MatDialog,
    private router: Router,
    private _fastRatesWidget: UntypedFormBuilder,
    private searchService: SearchParamsService,
    private cdref: ChangeDetectorRef,
    private titleService: Title,
    private metaTagService: Meta,
    private eventService: EventService,
    private breakpointObserver: BreakpointObserver,
    private location: Location
  ) {}

  ngOnInit() {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );
    if (window.location.hostname.includes('mountainmanagement.com')) {
      this.mmOnly = true;
    }

    this.route.fragment.subscribe((fragment) => {
      this.fragment = fragment;
    });

    this.loading = true;
    this.loadingUnits = true;
    this.noResult = false;
    this.unitsCount = 0;
    this.noMoreUnits = false;
    this.subMedia = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        this.isMobile = result.breakpoints[Breakpoints.XSmall];
        this.isSmall = result.breakpoints[Breakpoints.Small];
      });

    try {
      const innerWidth = window.innerWidth <= 1366 ? window.innerWidth : 1366;
      const width = innerWidth < mobileSM ? innerWidth : innerWidth / 2;
      this.spaImagePrefix = '?auto=enhance&w=' + width;
    } catch (e) {
      this.spaImagePrefix = 'auto=enhance';
      console.error(
        'Problem with window.innerWidth detection, request full size image'
      );
    }

    let theDay = this.route.snapshot.queryParams.StartDate
      ? moment.utc(this.route.snapshot.queryParams.StartDate)
      : null;
    let season: 'Winter' | 'Summer' | 'None' = theDay
      ? this.pickSeason(theDay)
      : 'None';

    this.sub = this.route.params.subscribe((params) => {
      this.unitCode = params['unitSlug__c'];
      this.complex = null;

      this.unitService.getUnit(this.unitCode, true).subscribe((data: any) => {
        if (data && data.length > 0) {
          this.complex = data[0];

          const longDescription = this.complex.Long_Description__c || '';
          const words = longDescription.split(' ');
          this.moreThen200words = words.length > 200;

          this.cdref.detectChanges();
          this.scrollToFragment();

          this.prepareImages(season);
          if (this.complex && this.complex.MarketingHeadline__c) {
            this.titleService.setTitle(this.complex.MarketingHeadline__c);
            this.metaTagService.updateTag({
              name: 'description',
              content: this.complex.MarketingHeadline__c,
            });
          } else {
            console.error(
              'marketing headline does not exist for complex',
              this.complex
            );
          }
          this.unitExist = true;
          this.prepopulateData();
          this.loading = false;
          this.shortText = true;
          this.showMoreImages = false;
          this.parseAmenities();
          this.eventService.sendViewItemEventGA({
            ComplexName: this.complex.Name,
            currency: 'USD',
            value: 0,
            items: [
              {
                item_id: this.complex.externalId,
                item_name: this.complex.Name,
              },
            ],
          });
        } else {
          this.loading = false;
          this.unitExist = false;
          throw new Error(
            'Get Complex problem. User requested complex ' +
              this.unitCode +
              '; URL params: ' +
              params
          );
        }
      });
    });

    this.fastRatesWidget = this._fastRatesWidget.group({
      arrivalForRates: [null, [Validators.required]],
      departureForRates: [null, [Validators.required]],
      numAdults: [2, []],
      numChildren: [0, []],
    });
  }

  private scrollToFragment(): void {
    // Use setTimeout to ensure scrolling happens after the view update
    setTimeout(() => {
      if (this.fragment) {
        this.viewportScroller.scrollToAnchor(this.fragment);
      }
    }, 0);
  }

  parseAmenities() {
    if (
      this.complex.locationAmenities &&
      this.complex.locationAmenities.length
    ) {
      this.locationAmenitiesParsed = [];
      this.locationCategories.forEach((category) => {
        const tmp = this.complex.locationAmenities
          .filter((amenity) => amenity.Category === category.Name)
          .map((amenity) => amenity.Name);
        if (tmp && tmp.length) {
          this.locationAmenitiesParsed.push({
            Category: category.Name,
            icon: category.Icon,
            List: tmp,
          });
        }
      });
    } else {
      this.locationAmenitiesParsed = null;
    }
  }

  prepopulateData() {
    /* Parsing URL if users comes from search -> then get and check all filled data */
    this.route.queryParams.subscribe((params) => {
      this.searchQuery = {};
      let sleepsCount = 0;
      if (params.Adults) {
        this.fastRatesWidget.controls['numAdults'].setValue(+params.Adults);
        this.searchQuery = { Adults: params.Adults, ...this.searchQuery };
        sleepsCount += +params.Adults;
      }
      if (params.Children) {
        this.fastRatesWidget.controls['numChildren'].setValue(+params.Children);
        this.searchQuery = { Children: params.Children, ...this.searchQuery };
        sleepsCount += +params.Children;
      }
      /*
      if (params.Bedrooms) {
        this.searchQuery = {
          Bedrooms: params.Bedrooms,
          ...this.searchQuery,
        };
      }*/
      if (sleepsCount > 0) {
        this.searchQuery = {
          SleepsCount: sleepsCount,
          ...this.searchQuery,
        };
      }
      if (params.UnitName) {
        this.searchQuery = { UnitName: params.UnitName, ...this.searchQuery };
      }
      /*if (params.Bedrooms) {
        this.searchQuery = { Bedrooms: params.Bedrooms, ...this.searchQuery };
      }*/
      if (params.PetFriendly) {
        this.searchQuery = {
          PetFriendly: params.PetFriendly,
          ...this.searchQuery,
        };
      }
      if (params.PropertyType) {
        if (params.PropertyType !== 'All') {
          this.searchQuery = {
            PropertyType: params.PropertyType,
            ...this.searchQuery,
          };
        }
      }
      if (params.PropertyCollection) {
        if (params.PropertyCollection !== 'All') {
          this.searchQuery = {
            PropertyCollection: params.PropertyCollection,
            ...this.searchQuery,
          };
        }
      }
      if (params.rateTop && params.rateBottom) {
        this.searchQuery = { rateTop: params.rateTop, ...this.searchQuery };
        this.searchQuery = {
          rateBottom: params.rateBottom,
          ...this.searchQuery,
        };
      }
      if (params.StartDate && params.EndDate) {
        this.arrivalField = moment.utc(params.StartDate, 'YYYY-MM-DD');
        this.departureField = moment.utc(params.EndDate, 'YYYY-MM-DD');
        this.myStay = moment
          .duration(this.departureField.diff(this.arrivalField))
          .asDays();
        this.fastRatesWidget.controls['arrivalForRates'].setValue(
          this.arrivalField
        );
        this.fastRatesWidget.controls['departureForRates'].setValue(
          this.departureField
        );
        this.datesfromSearch = true;

        const today = moment.utc(new Date());
        const arrival = moment.utc(params.StartDate, 'YYYY-MM-DD');
        const departure = moment.utc(params.EndDate, 'YYYY-MM-DD');
        if (arrival.isAfter(today) && departure.isAfter(arrival)) {
          this.myStay = moment.duration(departure.diff(arrival)).asDays();
          this.searchQuery = {
            StartDate: params.StartDate,
            EndDate: params.EndDate,
            myStay: this.myStay,
            ...this.searchQuery,
          };
        }
      }

      if (params.PetFriendly) {
        this.searchQuery = {
          PetFriendly: params.PetFriendly,
          ...this.searchQuery,
        };
      }
      this.clearBeforeNewSearch();
      this.searchService.update(this.searchQuery);
      this.searchUnits();
    });
  }

  viewDetails(roomSlug: string) {
    this.router.navigate(['/unit-details', roomSlug], {}); // FIXME roomSlug queryParams: this.searchQuery
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  getRating(event) {
    this.reviewsRating = event;
  }

  ngOnDestroy() {
    if (this.subMedia) {
      this.subMedia.unsubscribe();
    }
  }

  inquiryNow() {
    const dialogRef = this.dialog.open(UnitEnquiryDialogComponent, {
      data: {
        unit: this.complex,
        filter: {
          arrival: this.arrivalField ? this.arrivalField.toDate() : null,
          departure: this.departureField ? this.departureField.toDate() : null,
          adults: +this.fastRatesWidget.controls['numAdults'].value,
          children: +this.fastRatesWidget.controls['numChildren'].value,
        },
      },
      autoFocus: false,
      panelClass: 'enquiry-dialog-container',
      maxWidth: '96vw',
      maxHeight: '80vh',
      width: 'auto',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate(['/booking-enquiry-thank-you']);
        this.unitService.startBooking(result);
      }
    });
  }

  arrivalFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc(Date.now());
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    const day_moment = moment.utc(d_any);
    return day_moment.isAfter(today);
  };

  departureFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc(Date.now());
    const day_moment = moment.utc(d_any);
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    if (this.arrivalField !== null) {
      return (
        d_any.isAfter(today) &&
        d_any.isSameOrAfter(moment.utc(this.arrivalField))
      );
    } else {
      return day_moment.isAfter(today);
    }
  };

  arrivalChanged(event) {
    this.arrivalField = moment.utc(event.value).clone();
    this.lastChangedArrival = true;
    this.handleDateUpdate();
  }
  departureChanged(event) {
    this.departureField = moment.utc(event.value).clone();
    this.lastChangedArrival = false;
    this.handleDateUpdate();
  }

  handleDateUpdate() {
    if (
      this.arrivalField &&
      this.departureField &&
      this.arrivalField.isSameOrAfter(this.departureField)
    ) {
      if (this.lastChangedArrival) {
        this.departureField = null;
        this.fastRatesWidget.controls['departureForRates'].setValue(null);
      } else {
        this.arrivalField = null;
        this.fastRatesWidget.controls['arrivalForRates'].setValue(null);
      }
    }
    if (!this.departureField && this.arrivalField) {
      this.departureField = this.arrivalField.clone();
      this.departureField.add(4, 'days');
      this.fastRatesWidget.controls['departureForRates'].setValue(
        this.departureField
      );
    }
  }

  search() {
    /* update saved search params for prepopulation */
    if (this.arrivalField && this.departureField) {
      if (
        this.searchQuery &&
        this.searchQuery.StartDate &&
        this.searchQuery.EndDate
      ) {
        delete this.searchQuery['StartDate'];
        delete this.searchQuery['EndDate'];
        delete this.searchQuery['myStay'];
      }
    }
    let savedSearchQuery = this.searchService.get();
    if (
      savedSearchQuery &&
      savedSearchQuery.StartDate &&
      savedSearchQuery.EndDate
    ) {
      delete savedSearchQuery['StartDate'];
      delete savedSearchQuery['EndDate'];
      delete savedSearchQuery['myStay'];
    }
    this.myStay = moment
      .duration(this.departureField.diff(this.arrivalField))
      .asDays();

    this.searchQuery = {
      StartDate: this.arrivalField.format('YYYY-MM-DD'),
      EndDate: this.departureField.format('YYYY-MM-DD'),
      myStay: this.myStay,
      ...this.searchQuery,
    };
    savedSearchQuery = {
      StartDate: this.arrivalField
        ? this.arrivalField.format('YYYY-MM-DD')
        : null,
      EndDate: this.departureField
        ? this.departureField.format('YYYY-MM-DD')
        : null,
      myStay: this.myStay,
      ...savedSearchQuery,
    };
    this.searchService.update(savedSearchQuery);

    this.clearBeforeNewSearch();
    this.searchUnits();
  }

  numberofGuestChanged(field: string, event: any) {
    const numAdults = this.fastRatesWidget.controls['numAdults'].value;
    const numChildren = this.fastRatesWidget.controls['numChildren'].value;
    if (this.searchQuery && this.searchQuery.Children) {
      delete this.searchQuery['Children'];
    }
    if (this.searchQuery && this.searchQuery.Adults) {
      delete this.searchQuery['Adults'];
    }
    if (this.searchQuery && this.searchQuery.SleepsCount) {
      delete this.searchQuery['SleepsCount'];
    }
    this.searchQuery = {
      Adults: +numAdults,
      Children: +numChildren,
      SleepsCount: +numAdults + +numChildren,
      ...this.searchQuery,
    };

    let savedSearchQuery = this.searchService.get();
    if (savedSearchQuery && savedSearchQuery.Adults) {
      delete savedSearchQuery['Adults'];
    }
    if (savedSearchQuery && savedSearchQuery.Children) {
      delete savedSearchQuery['Children'];
    }
    if (savedSearchQuery && savedSearchQuery.SleepsCount) {
      delete savedSearchQuery['SleepsCount'];
    }
    savedSearchQuery = {
      Adults: +numAdults,
      Children: +numChildren,
      SleepsCount: +numAdults + +numChildren,
      ...savedSearchQuery,
    };
    this.searchService.update(savedSearchQuery);

    this.clearBeforeNewSearch();
    this.searchUnits();
  }

  searchUnits() {
    this.loadingUnits = true;

    const complexSearch = {
      complexId: this.complex.externalId,
      ...this.searchQuery,
    };
    const urlSearchParams = new URLSearchParams(complexSearch).toString();

    this.unitService
      .unitSearch(
        complexSearch,
        this.mmOnly,
        urlSearchParams,
        this.pageSize,
        this.offset
      )
      .subscribe((resp: { units?: any[] }) => {
        this.loadingUnits = false;
        this.parseResp(resp);
      });
  }

  parseResp(resp) {
    if (resp) {
      let moreunits = [];
      if (resp.error && resp.error === 'TooManyRequests') {
        console.log('TooManyRequests!');
        throw new Error('TooManyRequests error in search listing');
      }
      this.offset += this.pageSize;
      if (resp.units) {
        if (resp.units.length < this.pageSize) {
          this.noMoreUnits = true;
        } else {
          this.noMoreUnits = false;
        }
        this.unitsCount += resp.units.length;
        moreunits = resp.units;
        moreunits.forEach((unit: any) => {
          let rent = 0;
          if (unit.requestrate && unit.requestrate.length > 0) {
            let negativerent = false;
            for (
              let index = 0;
              index < unit.requestrate.length - 1 && !negativerent;
              index++
            ) {
              if (unit.requestrate[index] > 0) {
                rent += unit.requestrate[index];
              } else {
                negativerent = true;
              }
            }
            if (negativerent) {
              rent = null;
            }
          } else {
            rent = null;
          }
          unit.Rent = rent;
          unit.Total = null;
          unit.rateDetails = null;
          unit.inntopiaRate = null;
          unit.showDetails = false;
        });
        this.noResult = false;
      } else {
        if (this.unitsCount <= 0) {
          this.noResult = true;
        } else {
          this.noMoreUnits = true;
        }
      }

      if (this.unitDisplay) {
        let unitList: any[];
        this.unitDisplay.subscribe((currentUnits) => {
          unitList = _.union(currentUnits, moreunits);
        });
        this.unitDisplay = of(unitList);
      } else {
        this.unitDisplay = of(moreunits);
      }

      this.cdref.detectChanges();
      return;
    }
  }

  prepareImages(season: 'Winter' | 'Summer' | 'None') {
    let allUnitImages = this.complex.Images__r;

    if (season === 'None') {
      this.complex.ImagesProcessed = allUnitImages.sort((a, b) =>
        this.sortPriority(a, b)
      );
    } else {
      let filteredImages = allUnitImages.filter(
        (image: UnitImage) =>
          (image.Season__c === season || image.Season__c === 'Any') &&
          image.Display__c !== 'Thumbnail'
      );
      if (filteredImages.length >= 10) {
        this.complex.ImagesProcessed = filteredImages.sort((a, b) =>
          this.sortSeason(a, b, season)
        );
      } else {
        this.complex.ImagesProcessed = allUnitImages.sort((a, b) =>
          this.sortSeason(a, b, season)
        );
      }
    }
  }

  pickSeason(arrival: moment.Moment): 'Winter' | 'Summer' {
    let month = arrival.month(); // jan=0, dec=11
    let season: 'Winter' | 'Summer' =
      month > 2 && month < 10 ? 'Summer' : 'Winter';
    return season;
  }

  sortPriority(x, y) {
    let result;
    if (x.Display__c !== y.Display__c) {
      return x.Display__c === 'Hero' ? 1 : -1;
    } else {
      const a = x.DisplayOrder__c;
      const b = y.DisplayOrder__c;

      const asc = true;

      if (a === null) {
        return 1;
      }
      if (b === null) {
        return -1;
      }
      if (a === null && b === null) {
        return 0;
      }

      result = a - b;

      if (isNaN(result)) {
        return asc
          ? a.toString().localeCompare(b)
          : b.toString().localeCompare(a);
      } else {
        return asc ? result : -result;
      }
    }
  }

  sortSeason(x, y, season: 'Winter' | 'Summer') {
    let result;
    if (x.Display__c !== y.Display__c) {
      return x.Display__c === 'Hero' ? -1 : 1;
    } else {
      if (x.Season__c !== y.Season__c) {
        if (x.Season__c === season) {
          return -1;
        } else if (y.Season__c === season) {
          return 1;
        } else if (x.Season__c === 'Any') {
          return -1;
        } else if (y.Season__c === 'Any') {
          return 1;
        }
      } else {
        const a = x.DisplayOrder__c;
        const b = y.DisplayOrder__c;

        const asc = true;

        if (a === null) {
          return 1;
        }
        if (b === null) {
          return -1;
        }
        if (a === null && b === null) {
          return 0;
        }

        result = a - b;

        if (isNaN(result)) {
          return asc
            ? a.toString().localeCompare(b)
            : b.toString().localeCompare(a);
        } else {
          return asc ? result : -result;
        }
      }
    }
  }

  clearBeforeNewSearch() {
    this.unitsCount = 0;
    this.offset = 0;
    this.showAllUnits = false;
    this.noResult = false;
    this.noMoreUnits = false;
    this.unitDisplay = of([]);
    this.cdref.detectChanges();
  }

  back(): void {
    this.location.back();
  }

  reset() {
    this.arrivalField = null;
    this.departureField = null;
    this.showAllUnits = false;
    this.unitsCount = 0;
    this.offset = 0;
    this.myStay = 0;
    this.noResult = false;
    this.noMoreUnits = false;
    this.fastRatesWidget.controls['arrivalForRates'].setValue(
      this.arrivalField
    );
    this.fastRatesWidget.controls['departureForRates'].setValue(
      this.departureField
    );
    this.searchQuery = {};

    this.unitDisplay = of([]);

    this.cdref.detectChanges();
    this.searchUnits();
  }
}

<div class="rates-widget-container">
  <div class="summary-info-data new-brand-color">
    <span
      >{{ unit.Bedrooms__c }} Bedrooms · {{ unit.Bathrooms__c }} Bathrooms
      @if(!complexCard){· Sleeps {{ unit.Sleeps__c }}}</span
    >

    @if(unit.DisplayRates__c){
    <span class="float-right calendar-icon">
      <span
        class="float-right new-brand-color calendar-icon-new"
        style="font-size: small"
        (click)="showAvailability()"
      >
        <mat-icon>date_range</mat-icon>
      </span>
    </span>
    }
  </div>

  @if((!unit.DisplayRates__c || rent<=0 )&& this.availabilitySearch&&
  this.complexCard ){
  <div class="summary-info-data new-brand-color rate-chip-height">
    {{ unit.DisplayRate__c ? unit.DisplayRate__c : "Inquire now!" }}
  </div>
  } @if(unit.DisplayRates__c){
  <div
    *ngIf="showDatepickers"
    style="width: 100%; margin-top: 5px"
    [@inOutDates]
  >
    <form [formGroup]="fastRatesWidget" class="date-input-form">
      <span
        class="float-left brand-light-background new-brand-color date-input"
      >
        <input
          matInput
          readonly
          [matDatepicker]="arrivalPicker"
          placeholder="Arrival"
          [matDatepickerFilter]="arrivalFilterFunction"
          formControlName="arrivalForRates"
          (dateChange)="arrivalChanged($event)"
          (click)="arrivalPicker.open()"
        />
        <mat-datepicker #arrivalPicker [touchUi]="isMobile"></mat-datepicker>
      </span>

      <span
        class="float-right brand-light-background new-brand-color date-input"
      >
        <input
          matInput
          readonly
          placeholder="Departure"
          [matDatepicker]="departurePicker"
          [matDatepickerFilter]="departureFilterFunction"
          formControlName="departureForRates"
          (dateChange)="departureChanged($event)"
          (click)="departurePicker.open()"
        />

        <mat-datepicker
          [touchUi]="isMobile"
          #departurePicker
          [startAt]="arrivalDate"
          [dateClass]="dateClass"
        >
        </mat-datepicker>
      </span>
    </form>
  </div>

  @if (!(showQuote && showDatepickers) && rent>0){

  <mat-chip-option
    [selected]="showQuote"
    (click)="getQuote()"
    class="float-left brand-dark-background new-brand-color"
  >
    <span *ngIf="!showQuote" class="new-brand-color"
      >Rent: ${{ rent | number : "1.0-0" }}</span
    >
    <span *ngIf="showQuote"> Quote: </span>
    <mat-icon
      class="expand-icon new-brand-color"
      *ngIf="!showQuote"
      matChipRemove
      (click)="getQuote($event)"
    >
      expand_more</mat-icon
    >
    <mat-icon
      class="expand-icon"
      *ngIf="showQuote"
      matChipRemove
      (click)="changeShowQuote()"
    >
      expand_less</mat-icon
    >
  </mat-chip-option>
  }

  <div
    *ngIf="showQuote"
    [@inOutRates]
    class="brand-light-background new-brand-color rate-details-expanded"
  >
    <div *ngIf="!this.unit.isInntopiaUnit__c && !this.errorMessage">
      <div>
        <span class="float-left total-line">Total: </span>
        <span class="float-right" *ngIf="!rateLoaded">
          <span class="typing-dot1">.</span><span class="typing-dot2">.</span
          ><span class="typing-dot3">.</span>
        </span>
        <span
          *ngIf="rateLoaded && rateDetails"
          class="float-right total-line"
          >{{ this.rateDetails.TotalCost__c | currency }}</span
        >
        <br />
      </div>

      <div>
        <span class="float-left">
          <span
            [class.striked-out]="
              rateDetails &&
              rateDetails.Discount__c &&
              rateDetails.Discount__c > 0 &&
              rateDetails.Discounted_Rent__c &&
              rateDetails.Discounted_Rent__c > 0
            "
            >Rent:
          </span>
        </span>
        <span class="float-right" *ngIf="!rateLoaded"> </span>

        <span *ngIf="rateLoaded" class="float-right"
          ><span
            [class.striked-out]="
              rateDetails &&
              rateDetails.Discount__c &&
              rateDetails.Discount__c > 0 &&
              rateDetails.Discounted_Rent__c &&
              rateDetails.Discounted_Rent__c > 0
            "
            >{{ rateDetails.TotalRent__c | currency }}</span
          ></span
        >
      </div>

      <div
        *ngIf="
          rateDetails &&
          rateDetails.Discount__c &&
          rateDetails.Discount__c > 0 &&
          rateDetails.Discounted_Rent__c &&
          rateDetails.Discounted_Rent__c > 0
        "
      >
        <span class="float-left">Discounted rent: </span>
        <span *ngIf="rateLoaded" class="float-right">{{
          rateDetails.Discounted_Rent__c | currency
        }}</span>
        <br />
      </div>

      <div
        *ngIf="
          rateDetails &&
          rateDetails.RefundableDeposit__c &&
          rateDetails.RefundableDeposit__c > 0
        "
      >
        <span class="float-left">Refundable Security Deposit: </span>
        <span *ngIf="rateLoaded" class="float-right">
          {{ rateDetails.RefundableDeposit__c | currency }}</span
        >
        <br />
      </div>

      <div>
        <span class="float-left">Taxes and Fees: </span>
        <span *ngIf="rateLoaded" class="float-right">
          {{
            rateDetails.DamageProtectionFee__c +
              rateDetails.CleaningService__c +
              rateDetails.GuestServicePackage__c +
              rateDetails.PetFee__c +
              rateDetails.TAX__c | currency
          }}</span
        >
        <span class="float-right" *ngIf="!rateLoaded"></span>
        <br />
      </div>
    </div>
    <!--end  *ngIf=' !this.unit.isInntopiaUnit__c' style='width: 80%' -->
    <div *ngIf="this.unit.isInntopiaUnit__c && !this.errorMessage">
      <div>
        <span class="float-left total-line">Total: </span>
        <span class="float-right" *ngIf="!rateLoaded">
          <span class="typing-dot1">.</span><span class="typing-dot2">.</span
          ><span class="typing-dot3">.</span>
        </span>
        <span *ngIf="rateLoaded" class="float-right total-line">{{
          this.inntopiaRate.rent +
            this.inntopiaRate.fees +
            this.inntopiaRate.taxes +
            this.inntopiaRate.optionalFees | currency
        }}</span>
        <br />
      </div>
      <div *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0">
        <span class="float-left striked-out">Rent: </span>
        <span *ngIf="rateLoaded" class="float-right striked-out">{{
          this.inntopiaRate.baseRent | currency
        }}</span>
        <br />
      </div>
      <div>
        <span
          *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0"
          class="float-left"
          >Discounted Rent:
        </span>
        <span
          *ngIf="inntopiaRate && inntopiaRate.savingsAmount === 0"
          class="float-left"
          >Rent:</span
        >
        <span *ngIf="rateLoaded" class="float-right">{{
          this.inntopiaRate.rent | currency
        }}</span>
        <br />
      </div>
      <div *ngIf="rateLoaded && inntopiaRate.taxes && inntopiaRate.taxes > 0">
        <span class="float-left">Taxes: </span>
        <span *ngIf="rateLoaded" class="float-right">{{
          this.inntopiaRate.taxes | currency
        }}</span>
        <br />
      </div>
      <div *ngIf="rateLoaded && inntopiaRate.fees && inntopiaRate.fees > 0">
        <span class="float-left">Fees: </span>
        <span *ngIf="rateLoaded" class="float-right">{{
          this.inntopiaRate.fees | currency
        }}</span>
        <br />
      </div>
      <div
        *ngIf="
          rateLoaded &&
          inntopiaRate.optionalFees &&
          inntopiaRate.optionalFees > 0
        "
      >
        <span class="float-left">Optional Fees: </span>
        <span *ngIf="rateLoaded" class="float-right">{{
          this.inntopiaRate.optionalFees | currency
        }}</span>
        <br />
      </div>
      <div
        *ngIf="rateLoaded && inntopiaRate && inntopiaRate.taxes <= 0"
        style="font-style: italic"
      >
        Please note that additional taxes and fees could be applied.
        <br />
      </div>
      <div
        *ngIf="inntopiaRate && inntopiaRate.savingsAmount > 0"
        style="font-weight: bold"
      >
        Book now to secure savings of
        {{ inntopiaRate.savingsAmount | currency }} on this reservation
      </div>
    </div>
    <!-- end *ngIf='rateChip && this.unit.isInntopiaUnit__c' -->
    <div
      *ngIf="quoteError && !errorMessage"
      class="rate-details-expanded brand-light-background brand-dark-color"
    >
      Online quote is unavailable for these dates. Please change dates or
      contact our team to book this property.
    </div>
    <div *ngIf="errorMessage" [innerHTML]="this.errorMessage"></div>
  </div>
  }
</div>

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatNativeDateModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';

import { ClipboardModule } from '@angular/cdk/clipboard';

import {
  MatMomentDateModule,
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GalleryModule } from '@ks89/angular-modal-gallery';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { UnitSummaryComponent } from './unit-summary/unit-summary.component';
import { UnitDetailsComponent } from './unit-details/unit-details.component';

import { UnitTabsComponent } from './unit-tabs/unit-tabs.component';
import { BookingDialogComponent } from './unit-booking/unit-booking-dialog/unit-rates.component';
import { UnitSearchListingComponent } from './unit-search-listing/unit-search-listing.component';
import { UnitEnquiryDialogComponent } from './unit-booking/unit-enquiry-dialog/unit-enquiry-dialog.component';
import { UnitEnquiryThankYouComponent } from './unit-booking/unit-enquiry-thank-you/unit-enquiry-thank-you.component';
import { BookingReservationThankYouComponent } from './unit-booking/booking-reservation-thankyou/booking-reservation-thankyou.component';
import { UnitAmenitiesComponent } from './unit-amenities/unit-amenities.component';
import { UnitReviewsComponent } from './unit-reviews/unit-reviews.component';
import { TopActionsComponent } from './top-actions/top-actions.component';
import { SidenavService } from '../services/sidenav.service';
import { UnitRelatedComponent } from './unit-related/unit-related.component';
import { SummaryCarouselComponent } from './summary-carousel/summary-carousel.component';
import { SharedModule } from '../shared/shared.module';
import { ComplexDetailsComponent } from './complex-details/complex-details.component';
import { FooterComponent } from './footer/footer.component';
import { MainPageComponent } from './main-page/main-page.component';
import { SummaryGhostComponent } from './unit-summary/summary-ghost/summary-ghost.component';
import { ComplexSummaryComponent } from './complex-summary/complex-summary.component';
import { ComplexAccommodationComponent } from './complex-details/complex-accommodation/complex-accommodation.component';

import { BookingReservationThankyouSuccessComponent } from './unit-booking/booking-reservation-thankyou-success/booking-reservation-thankyou-success.component';
import { BookingReservationThankyouFailComponent } from './unit-booking/booking-reservation-thankyou-fail/booking-reservation-thankyou-fail.component';
import { FavoriteSnackComponent } from './favorite-snack/favorite-snack.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { ShareDialogComponent } from './favorites/share-dialog/share-dialog.component';
import { FavoritesListComponent } from './favorites/favorites-list/favorites-list.component';
import { BookingReservationThankyouCancelComponent } from './unit-booking/booking-reservation-thankyou-cancel/booking-reservation-thankyou-cancel.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InquiryFormComponent } from './unit-booking/inquiry-form/inquiry-form.component';
import { PlusMinusInputComponent } from './plus-minus-input/plus-minus-input.component';

import { ComplexListingComponent } from './complex-listing/complex-listing.component';
import { MapSearchPageComponent } from './map-search-page/map-search-page.component';
import { MatBadgeModule } from '@angular/material/badge';
import { DomSanitizer } from '@angular/platform-browser';
import { RatesChipComponent } from './rates-chip/rates-chip.component';

const ACCOUNT_CIRCLE_SVG = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#103753"><path d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z"/></svg>`;
const PHONE_SVG = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#103753"><path d="M798-120q-125 0-247-54.5T329-329Q229-429 174.5-551T120-798q0-18 12-30t30-12h162q14 0 25 9.5t13 22.5l26 140q2 16-1 27t-11 19l-97 98q20 37 47.5 71.5T387-386q31 31 65 57.5t72 48.5l94-94q9-9 23.5-13.5T670-390l138 28q14 4 23 14.5t9 23.5v162q0 18-12 30t-30 12ZM241-600l66-66-17-94h-89q5 41 14 81t26 79Zm358 358q39 17 79.5 27t81.5 13v-88l-94-19-67 67ZM241-600Zm358 358Z"/></svg>`;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    RouterModule,
    GalleryModule,
    MatTabsModule,
    MatListModule,
    MatChipsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatTableModule,
    MatBadgeModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSliderModule,
    InfiniteScrollModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    SharedModule,
    ClipboardModule,
  ],
  declarations: [
    UnitSearchListingComponent,
    UnitSummaryComponent,
    UnitDetailsComponent,
    UnitTabsComponent,
    BookingDialogComponent,
    UnitEnquiryDialogComponent,
    UnitEnquiryThankYouComponent,
    BookingReservationThankYouComponent,
    UnitAmenitiesComponent,
    UnitReviewsComponent,
    RatesChipComponent,
    TopActionsComponent,
    UnitRelatedComponent,
    SummaryCarouselComponent,
    ComplexDetailsComponent,
    FooterComponent,
    MainPageComponent,
    SummaryGhostComponent,
    ComplexSummaryComponent,
    ComplexAccommodationComponent,
    BookingReservationThankyouSuccessComponent,
    BookingReservationThankyouFailComponent,
    FavoriteSnackComponent,
    FavoritesComponent,
    ShareDialogComponent,
    FavoritesListComponent,
    BookingReservationThankyouCancelComponent,
    ContactUsComponent,
    InquiryFormComponent,
    PlusMinusInputComponent,
    ComplexListingComponent,
    MapSearchPageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MatIconRegistry,
    SidenavService,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class UnitsModule {
  constructor(
    public matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    matIconRegistry.registerFontClassAlias('ttw', 'ttw');
    matIconRegistry.registerFontClassAlias('social', 'social');
    matIconRegistry.addSvgIconLiteral(
      'account-circle',
      sanitizer.bypassSecurityTrustHtml(ACCOUNT_CIRCLE_SVG)
    );
    matIconRegistry.addSvgIconLiteral(
      'phone-light',
      sanitizer.bypassSecurityTrustHtml(PHONE_SVG)
    );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { UnitsService } from 'src/app/services/units.service';
import { UnitShort } from '../../../store/models/complex.model';
import { mainsite, mm, staging } from '../../../store/models/hostings';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

import {
  InntopiaPromotions,
  PromoCondition,
} from 'src/app/store/models/inntopia-promotion';
import { ImageSize } from '../../rates-chip/rates-chip.component';

@Component({
  selector: 'twbooking-complex-accommodation',
  templateUrl: './complex-accommodation.component.html',
  styleUrls: [
    './complex-accommodation.component.scss',
    '../../../shared/maps-search/unit-geo-card.scss',
  ],
})
export class ComplexAccommodationComponent implements OnInit {
  @Input() unit: UnitShort;
  @Input() numAdults: number;
  @Input() numChildren: number;
  @Input() numPets: number;
  @Input() arrivalField: moment.Moment;
  @Input() departureField: moment.Moment;
  @Input() isMobile: boolean;
  @Input() isSmall: boolean;
  @Input() searchQuery: any;
  totalBeds = 0;
  nb = true;
  myStay = 0;
  imageSize: ImageSize = 'default';

  processedDeals: InntopiaPromotions[] = [];
  showDeals: string[] = [];

  constructor(private unitService: UnitsService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging)
    );
  }

  showPromotion() {
    const start = this.arrivalField ? this.arrivalField.toDate() : null;
    const end = this.departureField ? this.departureField.toDate() : null;
    if (this.processedDeals.length === 0) {
      this.unitService
        .getPromotions(
          this.unit.isInntopiaUnit__c
            ? this.unit.inntopiaSupplierId__c
            : '999999',
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaProductId__c : '1',
          this.unit.externalId,
          start,
          end
        )
        .subscribe((response: any) => {
          const availableDeals = response;
          if (!this.unit.isInntopiaUnit__c) {
            this.processedDeals = availableDeals;
          } else {
            this.processedDeals = [];
            let i = 0;
            while (availableDeals && i < availableDeals.length) {
              const promo: InntopiaPromotions = {
                Name: availableDeals[i].Name,
                PromotionCode__c: availableDeals[i].PromotionCode__c,
                Description__c: availableDeals[i].Description__c,
                ValidStart__c: availableDeals[i].ValidStart__c,
                ValidEnd__c: availableDeals[i].ValidEnd__c,
                BookByDate__c: availableDeals[i].BookByDate__c,
                Details: [],
              };
              const promoDetails: PromoCondition = {
                Discount__c: availableDeals[i].Discount__c,
                MinNights__c: availableDeals[i].MinNights__c,
                MaxNights__c: availableDeals[i].MaxNights__c,
              };
              promo.Details.push(promoDetails);
              while (
                i + 1 < availableDeals.length &&
                availableDeals[i + 1].Name === promo.Name &&
                availableDeals[i + 1].ValidStart__c === promo.ValidStart__c &&
                availableDeals[i + 1].ValidEnd__c === promo.ValidEnd__c
              ) {
                const promoDetails: PromoCondition = {
                  Discount__c: availableDeals[i + 1].Discount__c,
                  MinNights__c: availableDeals[i + 1].MinNights__c,
                  MaxNights__c: availableDeals[i + 1].MaxNights__c,
                };
                promo.Details.push(promoDetails);
                i++;
              }
              this.processedDeals.push(promo);
              i++;
            }
          }
        });
    }
  }

  async keepSize(size: ImageSize) {
    console.log('keepsize', size);
    if (
      size === 'default' &&
      this.unit.DisplayRates__c &&
      this.searchQuery &&
      this.searchQuery.StartDate
    ) {
      this.imageSize = 'middle';
    } else {
      this.imageSize = size;
    }
  }
}

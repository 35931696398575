<div class="unit-details-page">
  <div class="breadcrumb-bar" *ngIf="isMobile" class="top-icons-space-between">
    <button
      class="back-button"
      mat-button
      aria-label="Back button"
      (click)="back()"
    >
      <mat-icon class="brand-dark-color">keyboard_arrow_left</mat-icon>
      <span class="back-text">Back to homes listing</span>
    </button>
  </div>

  <div *ngIf="!loading && unitExist">
    <span *ngIf="complex.ImagesProcessed">
      <twbooking-unit-gallery [unit]="complex"></twbooking-unit-gallery>
    </span>
    <div class="unit-details-content-section">
      <div *ngIf="complex" class="unit-details-content-layout">
        <div class="unit-details-tab-container">
          <div class="mobile-description">
            <div class="mobile-content">
              <h1 class="title-heading">{{ complex.MarketingHeadline__c }}</h1>
              <div class="header-separator-left">
                <h2 class="title-heading">Overview</h2>
                <div class="separator"></div>
              </div>

              <div *ngIf="shortText">
                <div
                  class="standard-text"
                  [innerHTML]="
                    (complex?.Long_Description__c | words : 200).text | safeHtml
                  "
                ></div>
                @if(moreThen200words){
                <a class="show-more-nav" (click)="shortText = !shortText"
                  ><span>Read more about the space</span>
                  <span class="hover-arrows">
                    <mat-icon></mat-icon>
                    <mat-icon class="right-arrow-classic"
                      >keyboard_arrow_right</mat-icon
                    >
                    <mat-icon class="right-arrow-on-hover"
                      >arrow_forward</mat-icon
                    >
                  </span>
                </a>
                }
              </div>
              <div *ngIf="!shortText">
                <div
                  class="standard-text"
                  [innerHTML]="complex?.Long_Description__c | safeHtml"
                ></div>
                <a class="show-more-nav" (click)="shortText = !shortText"
                  ><span>Hide description</span>
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </a>
              </div>
              <br />

              <div class="header-separator-left" id="accommodations">
                <h2 class="title-heading">
                  Accommodations within {{ complex.MarketingHeadline__c }}
                </h2>
                <div class="separator"></div>
              </div>
              <br />

              <div>
                <!--Accommodations wrapper-->

                <!------------------------------Quote widget simplified---------------------------------------------->
                <div
                  *ngIf="!nb"
                  class="quote-widget-simplified brand-new-color white-background figure-shadow"
                >
                  <form
                    [formGroup]="fastRatesWidget"
                    class="search-form-row"
                    novalidate
                  >
                    <div class="input-field-wrapper">
                      <div class="action-full check-in">
                        <h3 class="new-brand-color">CHECK IN</h3>
                        <input
                          matInput
                          class="brand-dark-color date-input"
                          readonly
                          [matDatepicker]="arrivalPicker"
                          placeholder="Arrival"
                          formControlName="arrivalForRates"
                          [matDatepickerFilter]="arrivalFilterFunction"
                          (dateChange)="arrivalChanged($event)"
                          (click)="arrivalPicker.open()"
                        />
                        <mat-datepicker
                          #arrivalPicker
                          [touchUi]="isMobile"
                        ></mat-datepicker>
                      </div>
                      <div
                        class="right-divider brand-dark-opacity-16-background"
                      ></div>
                      <div class="action-full check-in">
                        <h3 class="new-brand-color">CHECK OUT</h3>
                        <input
                          matInput
                          class="brand-dark-color date-input"
                          readonly
                          placeholder="Departure"
                          [matDatepicker]="departurePicker"
                          formControlName="departureForRates"
                          [matDatepickerFilter]="departureFilterFunction"
                          (dateChange)="departureChanged($event)"
                          (click)="departurePicker.open()"
                        />
                        <mat-datepicker
                          #departurePicker
                          [startAt]="departureStart"
                          [dateClass]="dateClass"
                          [touchUi]="isMobile"
                        ></mat-datepicker>
                      </div>
                    </div>

                    <div class="action-wrapper">
                      <div [class.action-right]="!isMobile">
                        <button
                          class="ttw-button primary-button"
                          mat-button
                          type="button"
                          (click)="search()"
                        >
                          Search
                        </button>
                      </div>
                      <div [class.action-right]="!isMobile">
                        <button
                          class="ttw-button tertiary-button margin-left-16"
                          mat-button
                          type="button"
                          (click)="inquiryNow()"
                        >
                          Inquire now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <!-------------------End quote widget simplified-->

                <div
                  *ngIf="unitDisplay | async as unitDisplayList"
                  class="accommodation-card-columns"
                >
                  @for (room of unitDisplayList | slice : 0 : unitLimit; track
                  room.externalId; let i = $index) { @if( showAllUnits || i <=
                  3){
                  <twbooking-complex-accommodation
                    [unit]="room"
                    [numAdults]="
                      +this.fastRatesWidget.controls['numAdults'].value
                    "
                    [numChildren]="
                      +this.fastRatesWidget.controls['numChildren'].value
                    "
                    [numPets]="numPets"
                    [arrivalField]="arrivalField"
                    [departureField]="departureField"
                    [isMobile]="isMobile"
                    [isSmall]="isSmall"
                    [searchQuery]="searchQuery"
                  >
                  </twbooking-complex-accommodation>
                  } }
                  <div class="full-width">
                    @if(loadingUnits){

                    <div class="unit-loading-container">
                      <mat-spinner></mat-spinner>
                    </div>
                    }
                    <a
                      *ngIf="
                        unitDisplayList.length > 2 &&
                        (!noMoreUnits || showAllUnits === false)
                      "
                      class="show-more-nav"
                      (click)="showAllUnits = true; searchUnits()"
                      ><span>View more</span>
                      <span class="hover-arrows">
                        <mat-icon></mat-icon>
                        <mat-icon class="right-arrow-classic"
                          >keyboard_arrow_right</mat-icon
                        >
                        <mat-icon class="right-arrow-on-hover"
                          >arrow_forward</mat-icon
                        >
                      </span>
                    </a>
                    <a
                      *ngIf="showAllUnits"
                      class="show-more-nav"
                      (click)="showAllUnits = false"
                      ><span>Hide</span>
                      <mat-icon>keyboard_arrow_left</mat-icon>
                    </a>
                  </div>
                </div>

                <div
                  *ngIf="noResult"
                  [class.right]="!isMobile && !nb"
                  [class.full-widget]="isMobile || nb"
                >
                  <div class="no-units-container" *ngIf="!nb">
                    <div class="text-with-cta">
                      <span>Please </span>
                      <button
                        class="like-link new-brand-hover-light"
                        (click)="inquiryNow()"
                      >
                        inquire
                      </button>
                      <span> for</span>
                      <span> availability</span>
                      <span> in</span>
                      <span> this</span>
                      <span> complex.</span>
                    </div>
                  </div>
                  <div class="no-units-container" *ngIf="nb">
                    <p>Please inquire for availability in this complex.</p>
                  </div>
                </div>
              </div>

              <div *ngIf="complex.Restaurant && complex.Restaurant.length > 0">
                <br />
                <br />
                <br />
                <mat-divider></mat-divider>
                <br />
                <br />
                @for (dining of complex.Restaurant; track dining.Name; let i =
                $index) {
                <div>
                  <div
                    *ngIf="allDining || i === 0"
                    [class.complex-features-desktop]="!isMobile"
                    [class.complex-features-mobile]="isMobile"
                  >
                    <div *ngIf="isMobile || i % 2 === 0" class="half-amenity">
                      <img
                        src="{{ dining.encodedVenue }}"
                        alt="{{ dining.Caption__c }}"
                        width="100%"
                      />
                    </div>
                    <div
                      class="half-amenity"
                      [class.amenity-description-left]="
                        !isMobile && i % 2 === 0
                      "
                      [class.amenity-description]="!isMobile && i % 2 === 1"
                      [class.description-mobile]="isMobile"
                    >
                      <div *ngIf="i === 0" class="header-separator-left">
                        <h2 class="title-heading no-top-margin">
                          Dining/Restaurants
                        </h2>
                        <div class="separator"></div>
                      </div>
                      <h4>{{ dining.Name }}</h4>
                      <div [innerHTML]="dining.Description__c | safeHtml"></div>
                    </div>
                    <div *ngIf="!isMobile && i % 2 === 1" class="half-amenity">
                      <img
                        src="{{ dining.encodedVenue }}"
                        alt="{{ dining.Caption__c }}"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
                }
                <br />
              </div>
              <a
                *ngIf="
                  !allDining &&
                  complex.Restaurant &&
                  complex.Restaurant.length > 1
                "
                class="show-more-nav"
                (click)="allDining = !allDining"
                ><span>More dining places</span>
                <span class="hover-arrows">
                  <mat-icon></mat-icon>
                  <mat-icon class="right-arrow-classic"
                    >keyboard_arrow_right</mat-icon
                  >
                  <mat-icon class="right-arrow-on-hover"
                    >arrow_forward</mat-icon
                  >
                </span>
              </a>
              <a
                *ngIf="
                  allDining &&
                  complex.Restaurant &&
                  complex.Restaurant.length > 1
                "
                class="show-more-nav"
                (click)="allDining = !allDining"
                ><span>Hide</span>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </a>

              <div *ngIf="complex.Spa && complex.Spa.length > 0">
                <br />
                <br />
                <br />
                <mat-divider></mat-divider>
                <br />
                <br />
                @for (spa of complex.Spa; track spa.Name; let i = $index) {
                <div>
                  <div
                    *ngIf="allSPA || i === 0"
                    [class.complex-features-desktop]="!isMobile"
                    [class.complex-features-mobile]="isMobile"
                    class="brand-offwhite-background"
                  >
                    <div *ngIf="isMobile || i % 2 === 1" class="half-amenity">
                      <img
                        src="{{ spa.encodedVenue }}"
                        alt="{{ spa.Caption__c }}"
                        width="100%"
                      />
                    </div>
                    <div
                      class="half-amenity"
                      [class.amenity-description]="!isMobile && i % 2 === 0"
                      [class.amenity-description-left]="
                        !isMobile && i % 2 === 1
                      "
                      [class.description-mobile]="isMobile"
                    >
                      <div *ngIf="i === 0" class="header-separator-left">
                        <h2 class="title-heading no-top-margin">
                          Spa/Wellness
                        </h2>
                        <div class="separator"></div>
                      </div>
                      <h4>{{ spa.Name }}</h4>
                      <div [innerHTML]="spa.Description__c | safeHtml"></div>
                    </div>
                    <div *ngIf="!isMobile && i % 2 === 0" class="half-amenity">
                      <img
                        src="{{ spa.encodedVenue }}"
                        alt="{{ spa.Caption__c }}"
                        width="100%"
                      />
                    </div>
                  </div>
                  <br />
                </div>
                }
                <br />
              </div>
              <a
                *ngIf="!allSPA && complex.Spa && complex.Spa.length > 1"
                class="show-more-nav"
                (click)="allSPA = !allSPA"
                ><span>More Spa/Wellness</span>
                <span class="hover-arrows">
                  <mat-icon></mat-icon>
                  <mat-icon class="right-arrow-classic"
                    >keyboard_arrow_right</mat-icon
                  >
                  <mat-icon class="right-arrow-on-hover"
                    >arrow_forward</mat-icon
                  >
                </span>
              </a>
              <a
                *ngIf="allSPA && complex.Spa && complex.Spa.length > 1"
                class="show-more-nav"
                (click)="allSPA = !allSPA"
                ><span>Hide</span>
                <mat-icon>keyboard_arrow_left</mat-icon>
              </a>

              <br />
              <br />

              <mat-divider></mat-divider>
              <br />

              <div
                *ngIf="
                  locationAmenitiesParsed && locationAmenitiesParsed.length
                "
              >
                <div class="header-separator-left">
                  <h2 class="title-heading">Local Amenities</h2>
                  <div class="separator"></div>

                  <div class="location-amenity-group">
                    @for (category of locationAmenitiesParsed; track
                    category.Category; let i = $index) {
                    <div>
                      <div
                        *ngIf="showAllAmenities || i <= 1 || !isMobile"
                        class="location-amenity-column"
                      >
                        <div class="location-amenity-title">
                          <svg
                            fill="#103753"
                            style="
                              width: 24px;
                              height: 24px;
                              padding-top: 2px;
                              padding-bottom: 2px;
                              margin-right: 8px;
                            "
                            viewBox="0 0 28 28"
                          >
                            <svg:path [attr.d]="category.icon" />
                          </svg>
                          <span>{{ category.Category }}</span>
                        </div>
                        @for (amenity of category.List; track amenity) {
                        <div class="location-amenity-list">
                          <mat-icon class="brand-accent-color">check</mat-icon>
                          <span>{{ amenity }}</span>
                        </div>
                        }
                      </div>
                    </div>
                    }
                  </div>

                  <div *ngIf="showAllAmenities && isMobile">
                    <a
                      class="show-more-nav"
                      (click)="showAllAmenities = !showAllAmenities"
                      ><span>Hide amenities</span>
                      <mat-icon>keyboard_arrow_left</mat-icon>
                    </a>
                  </div>
                  <div
                    *ngIf="
                      isMobile &&
                      !showAllAmenities &&
                      locationAmenitiesParsed.length > 2
                    "
                  >
                    <a
                      class="show-more-nav"
                      (click)="showAllAmenities = !showAllAmenities"
                      ><span>Show all amenities</span>
                      <span class="hover-arrows">
                        <mat-icon></mat-icon>
                        <mat-icon class="right-arrow-classic"
                          >keyboard_arrow_right</mat-icon
                        >
                        <mat-icon class="right-arrow-on-hover"
                          >arrow_forward</mat-icon
                        >
                      </span>
                    </a>
                  </div>

                  <br />
                  <br />
                  <mat-divider></mat-divider>
                </div>
              </div>

              <twbooking-unit-amenities
                [unit]="complex"
              ></twbooking-unit-amenities>

              <div #reviewsAnchor>
                <twbooking-unit-reviews
                  [unit]="complex"
                  (rating)="getRating($event)"
                ></twbooking-unit-reviews>
              </div>

              <br #bottomLine />
            </div>
          </div>
        </div>
      </div>
      <twbooking-unit-related
        [unit]="null"
        [complex]="complex"
      ></twbooking-unit-related>
    </div>
  </div>

  <div class="loading-container-page" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!unitExist && !loading" class="unit-not-found">
    <h2 class="mat-h2">Oops!</h2>

    <h2>We are unable to find the property that you searched for.</h2>
    <p>Please check our inventory:</p>
    <p>
      <a
        [routerLink]="['/units']"
        routerLinkActive="router-link-active"
        class="show-more-nav"
        >View properties</a
      >
    </p>
  </div>

  <twbooking-footer></twbooking-footer>
</div>

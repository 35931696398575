import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  input,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SearchParamsService } from 'src/app/services/search-params.service';
import {
  localhost,
  mainsite,
  mm,
  staging,
} from 'src/app/store/models/hostings';
import {
  InntopiaRates,
  MMRate,
  QuoteData,
} from 'src/app/store/models/rate.models';
import * as moment from 'moment';
import { UnitsService } from 'src/app/services/units.service';
import { UnitSummary } from 'src/app/store/models/unit-summary.model';
import { trigger, transition, style, animate } from '@angular/animations';
import { BookedDatesService } from 'src/app/services/booked-dates.service';

export type ImageSize = 'default' | 'middle' | 'small';
@Component({
  selector: 'twbooking-rates-chip',
  templateUrl: './rates-chip.component.html',
  styleUrl: './rates-chip.component.scss',
  animations: [
    trigger('inOutDates', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms ease-out', style({ height: 35, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 35, opacity: 1 }),
        animate('200ms ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
    trigger('inOutRates', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms ease-out', style({ height: 66, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 66, opacity: 1 }),
        animate('200ms ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class RatesChipComponent implements OnInit, OnChanges {
  @Input() unit: UnitSummary;
  @Input() arrival: moment.Moment;
  @Input() departure: moment.Moment;
  @Input() complexCard: boolean;
  @Input() availabilitySearch: boolean;
  @Output() quoteEmitter = new EventEmitter<QuoteData>();
  @Output() sizeEmitter = new EventEmitter<ImageSize>();

  rateLoaded = false;
  quote: QuoteData = null;
  quoteError = null;
  rent: number;
  TotalCost = 0;
  bookedDates = [];
  arrivalDate = null;
  departureDate = null;
  showQuote = false; // if true, show expanded rate details
  showDatepickers = false; // if true, show arrival-departure datepickers
  isMobile = false;
  errorMessage: string = null;

  rateDetails: MMRate;
  inntopiaRate: InntopiaRates;
  inntopiaBaseRate: InntopiaRates;
  inntopiaPromo: InntopiaRates;

  nb = true;

  fastRatesWidget: UntypedFormGroup;

  constructor(
    private searchService: SearchParamsService,
    private unitService: UnitsService,
    private _fastRatesWidget: UntypedFormBuilder,
    private bookedDatesService: BookedDatesService
  ) {}

  ngOnInit() {
    this.nb = !(
      window.location.hostname.includes(mainsite) ||
      window.location.hostname.includes(mm) ||
      window.location.hostname.includes(staging) ||
      window.location.hostname.includes(localhost)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.fastRatesWidget = this._fastRatesWidget.group({
      arrivalForRates: null,
      departureForRates: null,
    });
    if (
      this.unit.requestrate &&
      this.unit.requestrate.length &&
      this.unit.requestrate.length > 0
    ) {
      this.rent = 0;
      let negativerent = false;
      for (
        let index = 0;
        index < this.unit.requestrate.length - 1 && !negativerent;
        index++
      ) {
        if (this.unit.requestrate[index] > 0) {
          this.rent += this.unit.requestrate[index];
        } else {
          negativerent = true;
        }
      }
      if (negativerent) {
        this.rent = -1;
      }
    }

    if (changes.arrivalDate || changes.departureDate) {
      console.log('Dates changed');
      // Logic here to handle the change
      // In this case, Angular's binding will automatically update the child component
      this.arrival = changes.arrivalDate.currentValue;
      this.departure = changes.departureDate.currentValue;

      this.getQuote(this.arrival, this.departure);
    }
  }

  getQuote(arrival?, departure?) {
    if (this.nb) {
      this.errorMessage = 'Please contact us to get a quote';
    } else {
      this.quote = null;
      let savedSearch = this.searchService.get();
      this.changeShowQuote();
      this.rateLoaded = false;
      this.TotalCost = 0;
      this.errorMessage = null;
      this.quoteError = false;
      let numAdults = 1;
      let numChildren = 0;
      let numPets = 0;
      if (savedSearch && savedSearch.Pets && this.unit.Pets_Allowed__c) {
        numPets = +savedSearch.Pets;
      } else {
        numPets = 0;
      }
      let arrivalDate: moment.Moment;
      let departureDate: moment.Moment;
      if (!arrival || !departure) {
        console.log('savedSearch = ', savedSearch);
        if (savedSearch && savedSearch.StartDate && savedSearch.EndDate) {
          arrivalDate = moment.utc(savedSearch.StartDate, 'YYYY-MM-DD');
          departureDate = moment.utc(savedSearch.EndDate, 'YYYY-MM-DD');
        }
        if (savedSearch && savedSearch.Adults && !this.complexCard) {
          numAdults = +savedSearch.Adults;
        }
        if (savedSearch && savedSearch.Children && !this.complexCard) {
          numChildren = +savedSearch.Children;
        }
        if (numAdults + numChildren > this.unit.Sleeps__c) {
          this.quote = null;
          this.errorMessage =
            'Please note that the maximum occupancy for this unit is ' +
            this.unit.Sleeps__c.toString() +
            ' guests.';
          this.quoteEmitter.emit(null);
        }
      } else {
        arrivalDate = moment.utc(arrival);
        departureDate = moment.utc(departure);
        savedSearch = {
          ...savedSearch,
          StartDate: arrivalDate.format('YYYY-MM-DD'),
          EndDate: departureDate.format('YYYY-MM-DD'),
        };
        this.searchService.update(savedSearch);
      }
      const myStay = moment.duration(departureDate.diff(arrivalDate)).asDays();
      if (!this.checkRange(arrivalDate, departureDate)) {
        this.departureDate = null;
        this.fastRatesWidget.controls['departureForRates'].setValue(null);
        this.errorMessage =
          'Please note that selected range is not available for booking. Choose another dates.';
        this.rateLoaded = true;
        this.TotalCost = -1;
        this.quote = null;
        return;
      }
      this.unitService
        .getQuote(
          this.unit.externalId,
          this.unit,
          numAdults,
          numChildren,
          numPets,
          arrivalDate,
          departureDate,
          true
        )
        .subscribe((result) => {
          if (result.errorMessage) {
            this.quote = null;
            this.quoteError = true;
            this.TotalCost = 0;
            this.errorMessage = result.errorMessage;
            this.quoteEmitter.emit(null);
          }
          if (result.Total) {
            this.TotalCost = result.Total;
            this.rateLoaded = true;
            if (result.rateDetails) {
              this.rateDetails = result.rateDetails;

              if (this.TotalCost > 0 && this.rateDetails.TotalRent__c > 0) {
                this.quoteError = false;
                if (this.rateDetails.arrival && this.rateDetails.departure) {
                  this.arrivalDate = moment.utc(this.rateDetails.arrival);
                  this.departureDate = moment.utc(this.rateDetails.departure);

                  this.fastRatesWidget.controls['arrivalForRates'].setValue(
                    this.arrivalDate
                  );
                  this.fastRatesWidget.controls['departureForRates'].setValue(
                    this.departureDate
                  );
                }
                this.quote = {
                  arrival: arrivalDate.format('YYYY-MM-DD'),
                  departure: departureDate.format('YYYY-MM-DD'),
                  adults: numAdults,
                  children: numChildren,
                  pets: numPets,
                  quoteMM: this.rateDetails,
                };
                this.quoteEmitter.emit(this.quote);
              } else {
                this.quote = null;
                this.quoteEmitter.emit(null);
                this.quoteError = true;
                this.errorMessage =
                  'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';
              }
            } else if (result.quoteInntopia) {
              this.inntopiaRate = result.quoteInntopia;
              this.quoteError = false;
              if (this.inntopiaRate.arrival && this.inntopiaRate.departure) {
                this.arrivalDate = moment.utc(
                  this.inntopiaRate.arrival,
                  'YYYY-MM-DD'
                );
                this.departureDate = moment.utc(
                  this.inntopiaRate.departure,
                  'YYYY-MM-DD'
                );
                this.fastRatesWidget.controls['arrivalForRates'].setValue(
                  this.arrivalDate
                );
                this.fastRatesWidget.controls['departureForRates'].setValue(
                  this.departureDate
                );
              }
              if (
                this.inntopiaRate.rent > 0 &&
                !this.errorMessage &&
                !this.quoteError
              ) {
                this.quote = {
                  arrival: arrivalDate.format('YYYY-MM-DD'),
                  departure: departureDate.format('YYYY-MM-DD'),
                  adults: numAdults,
                  children: numChildren,
                  pets: numPets,
                  quoteInntopia: this.inntopiaRate,
                };
                this.quoteEmitter.emit(this.quote);
              } else {
                this.quote = null;
                this.quoteEmitter.emit(null);
                this.quoteError = true;
                this.errorMessage =
                  'Please <a class="show-more-link-same-font" href="/contact-us">contact</a> the Travel Whisperer team, our Personal Travel Designers can assist with a quote for your dates.';
              }
            }
          }
        });
    }
  }

  arrivalFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc(Date.now());
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    const day_moment = moment.utc(d_any);
    return !this.bookedDates.includes(day) && day_moment.isAfter(today);
  };

  departureFilterFunction = (d: moment.Moment | null): boolean => {
    let d_any = d || moment.utc();
    const today = moment.utc(Date.now());
    const day_moment = moment.utc(d_any);
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    if (this.arrivalDate !== null) {
      return (
        !this.bookedDates.includes(day) &&
        d_any.isAfter(today) &&
        d_any.isSameOrAfter(moment.utc(this.arrivalDate))
      );
    } else {
      return !this.bookedDates.includes(day) && day_moment.isAfter(today);
    }
  };

  getBookedDates() {
    if (this.bookedDatesService.get(this.unit.externalId) !== undefined) {
      this.bookedDates = this.bookedDatesService.get(this.unit.externalId);
    } else {
      this.bookedDatesService
        .getBookedDates(
          this.unit.externalId,
          this.unit.isInntopiaUnit__c,
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaSupplierId__c : null,
          this.unit.isInntopiaUnit__c ? this.unit.inntopiaProductId__c : null
        )
        .subscribe((response) => {
          this.bookedDates = response;
        });
    }
  }

  arrivalChanged(event) {
    console.log('arrivalChanged value:', event);
    this.arrivalDate = moment.utc(event.value);
    if (
      !this.departureDate ||
      this.arrivalDate.isSameOrAfter(this.departureDate) ||
      (this.arrivalDate &&
        this.departureDate &&
        !this.checkRange(this.arrivalDate, this.departureDate))
    ) {
      this.departureDate = this.arrivalDate.clone();
      this.departureDate.add(4, 'days');
      if (this.checkRange(this.arrivalDate, this.departureDate)) {
        this.fastRatesWidget.controls['departureForRates'].setValue(
          this.departureDate
        );
      } else {
        this.departureDate = null;
        this.fastRatesWidget.controls['departureForRates'].setValue(null);
      }
    }
    if (this.arrivalDate && this.departureDate) {
      this.getQuote(
        this.fastRatesWidget.controls['arrivalForRates'].value,
        this.fastRatesWidget.controls['departureForRates'].value
      );
      this.changeShowQuote(true);
    }
  } // end arrivalChanged

  departureChanged(event) {
    console.log('departureChanged value:', event);
    this.departureDate = moment.utc(event.value);
    if (this.arrivalDate !== null) {
      this.getQuote(
        this.fastRatesWidget.controls['arrivalForRates'].value,
        this.fastRatesWidget.controls['departureForRates'].value
      );
      this.changeShowQuote(true);
    }
  }

  showAvailability() {
    this.showDatepickers = !this.showDatepickers;
    this.getBookedDates();
    if (!this.showDatepickers) {
      this.showQuote = false;
    } else {
      this.prepopulateDates();
    }
    if (this.showQuote || this.showDatepickers) {
      this.sizeEmitter.emit('small');
    } else {
      this.sizeEmitter.emit('default');
    }
  }

  dateClass = (d_any: any) => {
    const day = moment.utc(d_any).format('YYYY-MM-DD');
    if (this.arrivalDate) {
      const arrival = moment.utc(this.arrivalDate).format('YYYY-MM-DD');
      return day === arrival ? 'choosen-date' : undefined;
    } else {
      return undefined;
    }
  };

  checkRange(startDay: moment.Moment, endDay: moment.Moment): boolean {
    // checks if all the dates inside range are available
    let checked = true;
    const checkDay = startDay.clone();
    while (checked && checkDay.isSameOrBefore(endDay)) {
      const day = checkDay.format('YYYY-MM-DD');
      checked = !this.bookedDates.includes(day);
      checkDay.add(1, 'days');
    }
    return checked;
  }

  prepopulateDates() {
    const savedSearch = this.searchService.get();
    if (savedSearch && savedSearch.StartDate && savedSearch.EndDate) {
      /* If we have saved dates */
      if (
        this.checkRange(
          moment.utc(savedSearch.StartDate),
          moment.utc(savedSearch.EndDate)
        )
      ) {
        /* And requested dates range is allowed - then set all requered params and get quote */
        this.arrivalDate = moment.utc(savedSearch.StartDate);
        this.departureDate = moment.utc(savedSearch.EndDate);
        this.fastRatesWidget.controls['arrivalForRates'].setValue(
          this.arrivalDate
        );
        this.fastRatesWidget.controls['departureForRates'].setValue(
          this.departureDate
        );
        this.getQuote(
          this.fastRatesWidget.controls['arrivalForRates'].value,
          this.fastRatesWidget.controls['departureForRates'].value
        );
      }
    }
  }

  changeShowQuote(value?: boolean) {
    this.showQuote = value ? value : !this.showQuote;
    if (this.showQuote) {
      this.sizeEmitter.emit('small');
    } else {
      this.sizeEmitter.emit('middle');
    }
  }
}

<mat-card
  appearance="outlined"
  class="unit-summary-card white-background new-brand-color flex-container-column"
>
  <div
    (swipe)="onSwipe($event)"
    [class.img-default]="imageSize === 'default'"
    [class.img-middle]="imageSize === 'middle'"
    [class.img-reduced]="imageSize === 'small'"
  >
    <twbooking-summary-carousel
      complex="false"
      [images]="images"
      [show]="choosenAmenity"
      [slug]="unit.UnitSlug__c"
      [searchQuery]="searchQuery"
      [lazyLoad]="isMobile"
    >
    </twbooking-summary-carousel>

    <ng-template #AvailableDeals let-value>
      <div class="tooltip-container-small">
        <div class="tooltip-content white-color">
          <div *ngIf="processedDeals.length === 0">
            <p class="white-color">Loading available deals...</p>
          </div>
          <div *ngIf="!this.unit.isInntopiaUnit__c">
            @for (promo of processedDeals; track $index){
            <div style="text-align: left">
              <p class="white-color" style="font-weight: bold">
                {{ promo.Name }}
              </p>
              <p class="white-color" [innerHTML]="promo.Description__c"></p>
            </div>
            }
          </div>
          <div
            *ngIf="
              this.unit.isInntopiaUnit__c &&
              processedDeals &&
              processedDeals.length > 0
            "
          >
            @for (promo of processedDeals; track $index){
            <div style="text-align: left">
              <p class="white-color" style="font-weight: bold">
                {{ promo.Name }}*
              </p>
              @for (details of promo.Details; track $index){
              <ul>
                <li class="white-color">
                  {{ details.Discount__c }}% off {{ details.MinNights__c
                  }}<span
                    *ngIf="
                      details.MinNights__c !== details.MaxNights__c &&
                      details.MaxNights__c <= 10
                    "
                    >-{{ details.MaxNights__c }}</span
                  >
                  <span *ngIf="details.MaxNights__c > 10"> or more </span>
                  nights
                </li>
              </ul>
              }
              <p class="white-color">
                *for stays
                {{ promo.ValidStart__c | date : "mediumDate" }} to
                {{ promo.ValidEnd__c | date : "mediumDate" }} booked until
                {{ promo.BookByDate__c | date : "mediumDate" }}
              </p>
            </div>
            }
          </div>
        </div>
      </div>
    </ng-template>
    <div
      *ngIf="!nb && !favoriteUnit"
      class="favorite-icon"
      (click)="saveFavorite()"
      matTooltip="Please like the units that you are interested in to help our personal travel designers find the best options for you"
    >
      <mat-icon class="white-color">favorite</mat-icon>
    </div>
    <div
      *ngIf="!nb && favoriteUnit"
      class="favorite-icon"
      (click)="removeFavorite()"
    >
      <mat-icon class="red-color">favorite</mat-icon>
    </div>
    <a
      (click)="showPromotion()"
      (mouseenter)="showPromotion()"
      *ngIf="unit.promotioncount > 0"
      class="discount-sticker ttw-projected ttw-projected-paddings"
      ttw-tooltip
      smallTooltip="yes"
      [tooltipHtml]="AvailableDeals"
      tooltipHideDelay="1500"
    >
      Discounts available
    </a>
  </div>

  <div
    class="summary-info-container new-brand-color flex-even-column"
    [class.container-default]="imageSize === 'default'"
    [class.container-middle]="imageSize === 'middle'"
    [class.container-expanded]="imageSize === 'small'"
  >
    <h1 class="summary-info-title new-brand-color">
      <a
        class="summary-info-title new-brand-color"
        [routerLink]="['/unit-details', unit.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >{{ unit.MarketingHeadline__c }}</a
      >
    </h1>

    @if(!unit.requestrate || unit.ReviewCount > 0){
    <div class="summary-info-data">
      @if(!unit.requestrate){
      <span *ngIf="!unit.requestrate">{{ unit.DisplayRate__c }} </span>
      } @if(unit.ReviewRating > 0){
      <div class="reviews-container float-right">
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="unit.ReviewRating > 3.8"
          >star</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="unit.ReviewRating > 1 && unit.ReviewRating <= 3.8"
          >star_half</mat-icon
        >
        <mat-icon
          class="review-stars-light review-stars-summary"
          *ngIf="unit.ReviewRating <= 1"
          >star_border</mat-icon
        >
        <span class="review-text">
          {{ unit.ReviewRating | number : "1.2-2" }} ({{ unit.ReviewCount }})
        </span>
      </div>
      }
    </div>
    }

    <div class="full-width">
      <twbooking-rates-chip
        [unit]="unit"
        [availabilitySearch]="searchQuery && searchQuery.StartDate"
        (quoteEmitter)="keepQuote($event)"
        (sizeEmitter)="keepSize($event)"
      ></twbooking-rates-chip>
    </div>

    <div
      *ngIf="
        !unit.DisplayRates__c &&
        searchQuery &&
        searchQuery.StartDate &&
        searchQuery.EndDate
      "
      style="height: 32px; visibility: hidden"
    ></div>

    <div class="summary-actions new-brand-color">
      <!--summary-info-actions-->

      <a
        class="unit-summary-button primary-button"
        mat-button
        [routerLink]="['/unit-details', unit.UnitSlug__c]"
        routerLinkActive="router-link-active"
        [queryParams]="searchQuery"
        >View details</a
      >
      <a
        *ngIf="!nb && unit.DisplayRates__c"
        class="unit-summary-button secondary-button"
        mat-button
        (click)="bookNow()"
        >Book now</a
      >
      <span *ngIf="nb || !unit.DisplayRates__c" class="hidden-button"></span>
      <a
        *ngIf="!nb"
        [ngClass]="{ 'hidden-button': nb }"
        class="unit-summary-button tertiary-button"
        mat-button
        (click)="inquireNow()"
        >Inquire now</a
      >
      <span *ngIf="nb" class="hidden-button"></span>
    </div>
  </div>
</mat-card>

import {
  AfterViewInit,
  Directive,
  HostListener,
  OnDestroy,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[twbookingCurrencyFormatter]',
})
export class CurrencyFormatterDirective implements AfterViewInit, OnDestroy {
  private formatter: Intl.NumberFormat;

  constructor(@Self() private ngControl: NgControl) {
    this.formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    });
  }

  ngAfterViewInit() {
    this.setValue(this.formatPrice(this.ngControl.value));
  }

  @HostListener('focus') onFocus() {
    this.setValue(this.unformatValue(this.ngControl.value));
  }

  @HostListener('blur') onBlue() {
    let value = this.ngControl.value || '';
    let v_cleared = value.replace(/[^0-9.]+/g, '');
    !!value && this.setValue(this.formatPrice(v_cleared));
  }

  setValue(v) {
    this.ngControl.control.setValue(v, { emitEvent: false });
  }
  public formatPrice(v: number) {
    return this.formatter.format(v);
  }
  unformatValue(v) {
    return v.replace(/,/g, '');
  }

  ngOnDestroy() {
    this.setValue(this.unformatValue(this.ngControl.value));
  }
}

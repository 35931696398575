export const imageByAmenity = {
  'Games room':
    'https://images.thetravelwhisperer.com/JoEep6g644ns7JfMdca8S-7Fmpg=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/games-room.png',
  'Games Room':
    'https://ttw-unit-images.imgix.net/ttw-amenities/games-room.png',
  Pool: 'https://ttw-unit-images.imgix.net/ttw-amenities/pool1.png',
  Bar: 'https://ttw-unit-images.imgix.net/ttw-amenities/bar.png',
  'Air Conditioning':
    'https://images.thetravelwhisperer.com/w59lms4Ghtk01CsEufDodmAQJFo=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/air-conditioning.jpg',
  Gym: 'https://images.thetravelwhisperer.com/K7739jTdGWA1iF3jmZxSf-siu5U=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/gym.png',
  'Ski-in Ski-out':
    'https://images.thetravelwhisperer.com/6llh7rofl1O4qZK3imFvqLbxFE0=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/ski-in-ski-out.png',
  'Mountain Views':
    'https://images.thetravelwhisperer.com/DDcWUpfoKYsqQyH78ZWw9MfHQ-Y=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/mountain-view.png',
  Cinema:
    'https://images.thetravelwhisperer.com/MKzu1GDotO55XGc1Ws_IpVzahWE=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/cinema.png',
  'Hot Tub':
    'https://images.thetravelwhisperer.com/MyDPyHolvUAa3ovNdB8E4iO_2ks=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/hot-tub.jpg',
  'Hot tub':
    'https://images.thetravelwhisperer.com/MyDPyHolvUAa3ovNdB8E4iO_2ks=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/hot-tub.jpg',
  Fireplace:
    'https://images.thetravelwhisperer.com/N-NBwWIqniJnFOfZoOux-Tuxgg4=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/fireplace.png',
  'BBQ Grill':
    'https://images.thetravelwhisperer.com/fk3dV1NFmTo5kbuqr6lA-tgVi6k=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/bbq.png',
  'Dogs Allowed':
    'https://images.thetravelwhisperer.com/uFdDI7PqzjP85Z5WNCCYN3Y24Mw=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/pet-friendly.jpg',
  'Pet Friendly':
    'https://images.thetravelwhisperer.com/uFdDI7PqzjP85Z5WNCCYN3Y24Mw=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/pet-friendly.jpg',
  'Shuttle to Lifts':
    'https://images.thetravelwhisperer.com/YNEbioAodFXH6a0nmZ_toUG5pS0=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/shuttle-to-lifts.png',
  'Jacuzzi Tub':
    'https://images.thetravelwhisperer.com/ulOUAPlVDi734GRknNLKfkl1bWM=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/jacuzzi.jpeg',
  'Washer/Dryer':
    'https://images.thetravelwhisperer.com/QIRHVVxw54hgwQapelvPOG7bD4s=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/washing-machine.png',
  'Walk to Lifts':
    'https://images.thetravelwhisperer.com/Ib8bMqF0GyskJJgzvDXEntOoQaQ=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/walk-to-lifts.jpg',
  Sauna:
    'https://images.thetravelwhisperer.com/15gNNWjYUR0D_Yq--WYEQ66ShlU=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/sauna.jpg',
  'Steam Room':
    'https://images.thetravelwhisperer.com/w4AAiy_CB9hOi3je_So75fJlCkI=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/steam-room.jpg',
  'Tennis Court':
    'https://images.thetravelwhisperer.com/-ZGUa8fNZxr7IA6WLuUjeeUs_mg=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/tennis.jpg',
  'Day Spa':
    'https://images.thetravelwhisperer.com/iS35143LsqC4xlo1mYFO9UA-6iw=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/day-spa.jpg',
  'On-Site Dining':
    'https://images.thetravelwhisperer.com/csq6EL0VV4XXmHK6jdnAlSUHEOM=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/on-site-dining.jpg',
  'Valet Parking':
    'https://images.thetravelwhisperer.com/Lr3dI8lHWVXybAcsVCd63SuUfVo=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/valet-parking.jpg',
  'Housekeeping Service - Alternate':
    'https://images.thetravelwhisperer.com/X96xRImIwUcwhB3UaVEw0b66tJ8=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/Housekeeping.jpg',
  'Housekeeping Service - Daily':
    'https://images.thetravelwhisperer.com/X96xRImIwUcwhB3UaVEw0b66tJ8=/508x339/storage.googleapis.com/ttw-property-images/ttw-amenities/Housekeeping.jpg',
};
